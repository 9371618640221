import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Hero from "./screens/how-to-buy/hero"
import ThankYou from "./screens/how-to-buy/thank-you"

const HowToBuyThanks = ({ location }) => {
  return (
    <React.Fragment>
      <Hero />
      {(!location.state)
        ?
        <ThankYou />
        :
        <ThankYou
          first_name={location.state.first_name}
        />
      }
    </React.Fragment>
  )
}
export default HowToBuyThanks 

export const Head = () => (
  <Seo
		title="How to Buy"
		description="Request a product demo or more info here to see how Visby could fit into your point of care setting."
		image="/meta/how-to-buy.jpg"
  />
)
